<script lang="ts" setup>
import { ref } from "vue";
import PermissionCategoriesGrid from "@/components/iam/PermissionCategoriesGrid.vue";

const addLink = ref({ name: "IAM-PermissionCategoryAdd", params: { permissionCategoryId: "-", addModus: true } });
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <permission-categories-grid :addLink="addLink"></permission-categories-grid>
  </v-container>
</template>
